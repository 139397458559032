import React from "react";
import Box from "@mui/joy/Box";

const Sidebar = (props) => {
	return (
		<Box
			className="sidebar"
			{...props}
			sx={[
				{
					p: 3,
					bgcolor: "background.surface",
					borderRight: "1px solid",
					borderColor: "divider",
					boxShadow: ` 0px 11px 22px rgb(0 0 0 / 5%)`,
					display: {
						xs: "none",
						sm: "initial"
					}
				},
				...(Array.isArray(props.sx) ? props.sx : [props.sx])
			]}
		/>
	);
};

export default Sidebar;
