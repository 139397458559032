import DownloadFile from "../../utils/downloadFile";

import Link from "@mui/joy/Link";
import Button from "@mui/joy/Button";

import { fetchRelatedProductsCSV } from "./api";
import { useDispatch } from "react-redux";

const RelatedProductsCSVButton = ({
	variant,
	size = "md",
	button = true,
	productId,
	fileName,
	label = "Download CSV"
}) => {
	const dispatch = useDispatch();

	const handleClick = (event) => {
		event.stopPropagation();
		dispatch(fetchRelatedProductsCSV(productId)).then((data) => {
			const csv = data.payload;
			DownloadFile(fileName, csv);
		});
	};

	return button ? (
		<Button variant={variant} size={size} onClick={handleClick}>
			{label}
		</Button>
	) : (
		<Link variant={variant} size={size} onClick={handleClick}>
			{label}
		</Link>
	);
};

export default RelatedProductsCSVButton;
