import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { totalIherbKeywords } from "./iherbKeywordsSlice";

import { fetchNextSetOfKeywords } from "./api";

import { CustomTableDefault } from "../../components/UI/Tables";

const IherbKeywordsTable = () => {
	const dispatch = useDispatch();
	const keywords = useSelector((state) => state.iherbKeywords.keywords);
	const keywordsStatus = useSelector((state) => state.iherbKeywords.status);
	const totalKeywords = useSelector(totalIherbKeywords);

	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize] = useState(8);

	const handleNextPage = () => {
		const next_cursor = keywords[keywords.length - 1];

		if (currentPage + pageSize >= keywords.length) {
			// Fetch new data if the the list does not hold index
			dispatch(
				fetchNextSetOfKeywords({
					next_cursor: next_cursor._id
				})
			);
		}

		setCurrentPage(currentPage + pageSize);
	};

	const handlePreviousPage = () => {
		setCurrentPage(currentPage - pageSize);
	};

	return (
		<CustomTableDefault
			isLoading={keywordsStatus === "loading" ? true : false}
			title="Keywords"
			hoverRow={false}
			tableHead={[
				{
					label: "Keyword",
					key: "_id"
				}
			]}
			tableRow={keywords}
			pageSize={pageSize}
			tablePagination={{
				totalResults: totalKeywords,
				startIndex: currentPage,
				nextPage: handleNextPage,
				previousPage: handlePreviousPage
			}}
		></CustomTableDefault>
	);
};

export default IherbKeywordsTable;
