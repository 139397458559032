import React, { useEffect, useState, useMemo } from "react";
import { DateTime } from "luxon";
import { useSelector, useDispatch } from "react-redux";

import {
	fetchProducts,
	fetchKeywords,
	fetchDashboardStatus
} from "../../features/iherb/api";

// Matieral UI
import Box from "@mui/joy/Box";
import RefreshIcon from "@mui/icons-material/Refresh";

// Custom Components
import Header from "../../components/UI/Header";
import { Stats } from "../../components/DataVisualization";
import IherbKeywordsTable from "../../features/iherb/IherbKeywordsTable";
import IherbProductsTable from "../../features/iherb/IherbProductsTable";

function KeywordList() {
	const dispatch = useDispatch();
	const products = useSelector((state) => state.iherbProducts.products);
	const productStatus = useSelector((state) => state.iherbProducts.status);

	const keywordsStatus = useSelector((state) => state.iherbKeywords.status);

	const dashboard = useSelector(
		(state) => state.iherbDashboard.dashboardStatus
	);
	const dashboardStatus = useSelector((state) => state.iherbDashboard.status);

	const dashboardStatusText = useMemo(() => {
		let status = "";

		if (
			dashboard?.products_pending > 0 ||
			dashboard?.keywords_pending > 0
		) {
			status = "Processing";
		} else if (
			dashboard?.products_pending === 0 &&
			dashboard?.keywords_pending === 0
		) {
			status = "Complete";
		}
		return status;
	}, [dashboard]);

	const dashboardStatusLastRun = useMemo(() => {
		return dashboard?.last_updated === undefined
			? ""
			: DateTime.fromISO(dashboard?.last_updated).toLocaleString(
					DateTime.DATETIME_MED
			  );
	}, [dashboard]);

	const dashboardTotalPendingProducts = useMemo(() => {
		return dashboard?.products_pending;
	}, [dashboard]);

	const dashboardTotalProducts = useMemo(() => {
		return dashboard?.products_total;
	}, [dashboard]);

	const dashboardTotalPendingKeywords = useMemo(() => {
		return dashboard?.keywords_pending;
	}, [dashboard]);

	const dashboardTotalKeywords = useMemo(() => {
		return dashboard?.keywords_total;
	}, [dashboard]);

	const handleRefreshData = () => {
		console.log("refreshed");
	};

	useEffect(() => {
		if (productStatus === "idle" || products.length === 0) {
			dispatch(fetchProducts());
		}

		if (keywordsStatus === "idle") {
			dispatch(fetchKeywords());
		}

		if (dashboardStatus === "idle") {
			dispatch(fetchDashboardStatus());
		}
	}, [dispatch]);

	return (
		<Box>
			<Header title="IHerb Keyword Research" />
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "repeat(3, 1fr)",
					gap: 3,
					marginBottom: 5
				}}
			>
				<Stats
					label="Number of keywords"
					number={dashboardTotalKeywords}
					isLoading={dashboardStatus === "loading" ? true : false}
					// footer={
					// 	dashboardTotalPendingKeywords > 0
					// 		? `Processing ${dashboardTotalPendingKeywords} keywords`
					// 		: "Processing Complete"
					// }
				/>

				<Stats
					label="Total number products"
					number={dashboardTotalProducts}
					isLoading={dashboardStatus === "loading" ? true : false}
					// footer={
					// 	dashboardTotalPendingProducts > 0
					// 		? `Processing ${dashboardTotalPendingProducts} product${
					// 				dashboardTotalPendingProducts > 1 ? "s" : ""
					// 		  }`
					// 		: "Processing Complete"
					// }
				/>
				<Stats
					isLoading={dashboardStatus === "loading" ? true : false}
					label="Process Status"
					text={dashboardStatusText}
					color={
						dashboardStatusText === "Completed"
							? "success"
							: "warning"
					}
					footer={`Last update: ${dashboardStatusLastRun}`}
					action={{
						onClick: handleRefreshData,
						disabled:
							dashboardStatusText === "Completed" ? true : false,
						icon: <RefreshIcon />
					}}
				/>
			</Box>
			<IherbProductsTable />
			<IherbKeywordsTable />
		</Box>
	);
}

export default KeywordList;
