import React from "react";
import Skeleton from "@mui/material/Skeleton";

const PreloadSkelton = ({ totalItems }) => {
	const items = new Array(totalItems).fill(null);
	return items.map((_, idx) => (
		<Skeleton height={50} animation="wave" key={idx} />
	));
};

export default PreloadSkelton;
