import { createSlice } from "@reduxjs/toolkit";

const dialogSlice = createSlice({
	name: "dialog",
	initialState: {
		isOpen: false,
		message: "",
		title: "",
		error: false,
		primaryButton: null,
		secondaryButton: null
	},
	reducers: {
		openDialog: (state, action) => {
			state.isOpen = true;
			state.message = action.payload.message;
			state.title = action.payload.title;

			if (action.payload.error) {
				state.error = action.payload.error;
			}

			if (action.payload.primaryButton) {
				state.error = action.payload.primaryButton;
			}

			if (action.payload.secondaryButton) {
				state.error = action.payload.secondaryButton;
			}
		},
		closeDialog: (state) => {
			state.isOpen = false;
			state.message = "";
			state.title = "";
		}
	}
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
