import { createSlice } from "@reduxjs/toolkit";
import {
	fetchProducts,
	fetchProductByUPC,
	fetchNextSetOfProducts,
	fetchProductById,
	fetchProductKeywords,
	fetchProductCSV,
	fetchRelatedProductsCSV
} from "./api";

const initialState = {
	products: [],
	totalProducts: 0,
	productById: null,
	productByIdKeywords: [],
	productByIdCSV: null,
	productByIDCSVStatus: "idle",
	loading: false,
	loadingProductKeywords: false,
	loadingProductCSV: false,
	loadingProductById: false,
	status: "idle", //| "loading" | "succeeded" | "failed"
	error: null,
	pagination: {
		startIndex: 0,
		limit: 8
	}
};

export const iherbProductsSlice = createSlice({
	name: "iherbProducts",
	initialState,
	reducers: {
		setProduct: (state, action) => {
			state.productById = action.payload;
		},
		setProducts: (state, action) => {
			state.products = action.payload;
		},
		setProductsCurrentPageIndex: (state, action) => {
			state.pagination.startIndex = action.payload;
		}
	},
	extraReducers(builder) {
		builder
			.addCase(fetchProducts.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchProducts.fulfilled, (state, action) => {
				state.status = "succeeded";

				state.products = action.payload.results;
				state.totalProducts = action.payload.num_results;
				state.pagination = {
					...state.pagination,
					startIndex: 0
				};
			})
			.addCase(fetchProducts.rejected, (state, action) => {
				state.status = "failed";

				if (action.payload && action.payload.status === 404) {
					state.error = "User not found";
				} else if (action.payload) {
					let error = action.payload.detail;
					if (
						error === "expired" ||
						error === "invalid token: Invalid header padding" ||
						error === "invalid token: Signature verification failed"
					) {
						state.error = "User not found";
						alert(
							"Token expired you will be redirected back to the loging page"
						);
						localStorage.removeItem("userToken");
						window.location.href = "/login";
					} else {
						state.error = error;
					}
				} else if (action.error) {
					// handle network error
					state.error = action.error.message;
				}
			})
			.addCase(fetchNextSetOfProducts.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchNextSetOfProducts.fulfilled, (state, action) => {
				state.status = "succeeded";

				state.products = state.products.concat(action.payload.results);
			})
			.addCase(fetchNextSetOfProducts.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(fetchProductById.pending, (state, action) => {
				state.loadingProductById = true;
			})
			.addCase(fetchProductById.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.loadingProductById = false;
				state.productById = action.payload;
			})
			.addCase(fetchProductById.rejected, (state, action) => {
				state.status = "failed";
				state.loadingProductById = false;
				state.error = action.error.message;
				if (action.payload && action.payload.status === 404) {
					state.error = "User not found";
				} else if (action.payload) {
					let error = action.payload.detail;
					if (
						error === "expired" ||
						error === "invalid token: Invalid header padding" ||
						error === "invalid token: Signature verification failed"
					) {
						state.error = "User not found";
						alert(
							"Token expired you will be redirected back to the loging page"
						);
						localStorage.removeItem("userToken");
						window.location.href = "/login";
					} else {
						state.error = error;
					}
				} else if (action.error) {
					// handle network error
					state.error = action.error.message;
				}
			})
			.addCase(fetchProductKeywords.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchProductKeywords.fulfilled, (state, action) => {
				state.status = "succeeded";
				const totalProductPerPage = 48;
				let keywords = Object.entries(action.payload).map(
					([keyword, rank]) => {
						return {
							keyword,
							rank,
							page: Math.ceil(rank / totalProductPerPage)
						};
					}
				);
				state.productByIdKeywords = keywords;
			})
			.addCase(fetchProductKeywords.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(fetchProductByUPC.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchProductByUPC.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.productById = action.payload;
			})
			.addCase(fetchProductByUPC.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(fetchProductCSV.pending, (state) => {
				state.loadingProductCSV = true;
			})
			.addCase(fetchProductCSV.fulfilled, (state) => {
				state.loadingProductCSV = false;
			})
			.addCase(fetchProductCSV.rejected, (state, action) => {
				state.status = "failed";
				state.loadingProductCSV = false;
				state.error = action.error.message;
			})
			.addCase(fetchRelatedProductsCSV.pending, (state) => {
				state.loadingProductCSV = true;
			})
			.addCase(fetchRelatedProductsCSV.fulfilled, (state) => {
				state.loadingProductCSV = false;
			})
			.addCase(fetchRelatedProductsCSV.rejected, (state, action) => {
				state.status = "failed";
				state.loadingProductCSV = false;
				state.error = action.error.message;
			});
	}
});

export const { setProducts, setProduct, setProductsCurrentPageIndex } =
	iherbProductsSlice.actions;

export const totalProduct = (state) => state.iherbProducts.totalProducts;
export const selectAllProducts = (state) => state.iherbProducts.products;
export const selectProductById = (state) => state.iherbProducts.productById;
export const selectProductsByKeyword = (state) =>
	state.iherbProducts.productByIdKeywords;

export const selectPurchasedTogetherProducts = (state) =>
	state.iherbProducts.productById?.purchased_together;

export const selectRelatedProductsWidget = (state) =>
	state.iherbProducts.productById?.widget_related_products;

export const selectRelatedProducts = (state) =>
	state.iherbProducts.productById?.related_products;

export default iherbProductsSlice.reducer;
