import React, { useEffect } from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Chip from "@mui/joy/Chip";

import ProductCSVButton from "../features/iherb/IherbProductCSVButton";

const buildTableRow = (tableHead, dataRow, idx) => {
	let row = [];

	tableHead.map((th, idx) => {
		if (th.key === "csv") {
			row.push(
				<td key={idx} style={{ textAlign: "right" }}>
					<ProductCSVButton
						button={true}
						variant="soft"
						size="sm"
						productId={dataRow._id}
						fileName={`${th.fileName}_${dataRow._id}_${dataRow.brand}`}
					/>
				</td>
			);
		} else if (th.label.toLowerCase() === "product") {
			row.push(
				<Box
					component="td"
					sx={{
						display: "flex",
						fontWeight: 600
					}}
					key={idx}
				>
					<img
						src={dataRow[th.key.image]}
						alt={dataRow[th.key.name]}
					/>
					<Typography
						level="body3"
						sx={{
							ml: 1,
							display: "-webkit-box",
							" -webkit-line-clamp": 3,
							"-webkit-box-orient": "vertical",
							overflow: "hidden"
						}}
					>
						{dataRow[th.key.name]}
					</Typography>
				</Box>
			);
		} else if (
			th.key === "keyword" ||
			th.label.toLowerCase() === "keyword"
		) {
			row.push(
				<Box
					component="td"
					sx={{
						textAlign: "left"
					}}
					key={idx}
				>
					<Chip size="sm" variant="soft" color="warning">
						{dataRow[th.key]}
					</Chip>
				</Box>
			);
		} else if (th.key === "url") {
			row.push(
				<Box
					component="td"
					sx={{
						textAlign: "right"
					}}
					key={idx}
				>
					<IconButton
						aria-label="Open in new tab"
						component="a"
						target="_blank"
						href={dataRow[th.key]}
						variant="plain"
					>
						<OpenInNew />
					</IconButton>
				</Box>
			);
		} else if (th.key in dataRow) {
			row.push(<td key={idx}>{dataRow[th.key]}</td>);
		}
	});

	return row;
};

export default buildTableRow;
