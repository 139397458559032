import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import { useNavigate } from "react-router-dom";

function Header({ title, subtitle, breadcrumbs }) {
	const navigate = useNavigate();
	return (
		<Box component="header" mb={6} mt={1}>
			{subtitle !== "" ? (
				<Typography level="h6">{subtitle}</Typography>
			) : null}
			<Typography level="h3" component="h1">
				{title}
			</Typography>
			{breadcrumbs.length > 0 ? (
				<Breadcrumbs
					size="sm"
					separator="•"
					aria-label="breadcrumbs"
					sx={{ paddingLeft: 0 }}
				>
					{breadcrumbs.map((breadcrumb, idx) => {
						let list = [];

						if (idx + 1 !== breadcrumbs.length) {
							list.push(
								<Link
									onClick={(event) => {
										navigate(breadcrumb.link);
									}}
									key={breadcrumb.label}
									underline="hover"
									color="neutral"
									fontSize="inherit"
									// href={breadcrumb.link}
								>
									{breadcrumb.label}
								</Link>
							);
						}
						return list;
					})}
					<Typography fontSize="inherit">
						{breadcrumbs.at(-1).label}
					</Typography>
				</Breadcrumbs>
			) : null}
		</Box>
	);
}

Header.defaultProps = {
	title: "Page Title",
	subtitle: "",
	breadcrumbs: []
};

Header.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	breadcrumbs: PropTypes.array
};

export default Header;
