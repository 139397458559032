import React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

const Logo = () => {
	return (
		<Button
			variant="link"
			sx={{
				padding: 0,
				"&:hover": {
					backgroundColor: "transparent"
				}
			}}
		>
			<Typography
				fontWeight="lg"
				startDecorator={
					<Box component="span" sx={{ width: 30, display: "flex" }}>
						<img
							style={{ width: "100%" }}
							alt="VnSuite 2"
							src={`${process.env.PUBLIC_URL}/logo512.png`}
						/>
					</Box>
				}
			>
				<strong>VN</strong>Suite
			</Typography>
		</Button>
	);
};

export default Logo;
