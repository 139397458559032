import { createSlice } from "@reduxjs/toolkit";

import {
	fetchKeywords,
	fetchNextSetOfKeywords,
	fetchKeywordsByKeyword
} from "./api";

const initialState = {
	keywords: [],
	totalKeywords: 0,
	keywordsByKeyword: [],
	status: "idle", //| "loading" | "succeeded" | "failed"
	error: null
};

export const iherbKeywordsSlice = createSlice({
	name: "iherbKeywords",
	initialState,
	reducers: {
		setKeywords: (state, action) => {
			state.accessToken = action.payload;
		},
		setkeywordsByKeyword: (state, action) => {
			state.keywordsByKeyword = action.payload;
		}
	},
	extraReducers(builder) {
		builder
			.addCase(fetchKeywords.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchKeywords.fulfilled, (state, action) => {
				state.status = "succeeded";

				state.keywords = state.keywords.concat(action.payload.results);
				state.totalKeywords = action.payload.num_results;
			})
			.addCase(fetchKeywords.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(fetchKeywordsByKeyword.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchKeywordsByKeyword.fulfilled, (state, action) => {
				let keyword = action.meta.arg;
				state.status = "succeeded";

				state.keywordsByKeyword = action.payload.map((data) =>
					Object.assign({}, data, { keyword })
				);
			})
			.addCase(fetchKeywordsByKeyword.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(fetchNextSetOfKeywords.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchNextSetOfKeywords.fulfilled, (state, action) => {
				state.status = "succeeded";

				state.keywords = state.keywords.concat(action.payload.results);
			})
			.addCase(fetchNextSetOfKeywords.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	}
});

export const { setKeywords, setkeywordsByKeyword } = iherbKeywordsSlice.actions;

export const iherbKeywords = (state) => state.iherbKeywords.keywords;
export const totalIherbKeywords = (state) => state.iherbKeywords.totalKeywords;
export const selectedUPC = (state) => state.iherbKeywords.upc;

export default iherbKeywordsSlice.reducer;
