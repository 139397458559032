import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/joy/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { useDispatch, useSelector } from "react-redux";
import { getAccessToken, userIsAuthenticated } from "./authSlice";

import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isAuthenticated = useSelector(userIsAuthenticated);

	const isLoading = useSelector((state) => state.auth.isLoading);

	const handleSubmit = (event) => {
		event.preventDefault();
		isMobile
			? getAccessTokenSilently().then((token) => {
					dispatch(getAccessToken(token)).then(() => {
						if (isAuthenticated) {
							navigate("/iherb/keyword-research");
						}
					});
			  })
			: getAccessTokenWithPopup().then((token) => {
					dispatch(getAccessToken(token)).then(() => {
						if (isAuthenticated) {
							navigate("/iherb/keyword-research");
						}
					});
			  });
	};

	return (
		<Button
			// variant="soft"
			fullWidth
			onClick={handleSubmit}
			disabled={isLoading}
		>
			{!isLoading ? "Login" : <CircularProgress size={20} />}
		</Button>
	);
};

export default LoginButton;
