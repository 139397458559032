import React from "react";
import PropTypes from "prop-types";

import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/system";

const StateHeader = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between"
}));

const StateBody = styled(Box)(() => ({
	marginBottom: 28
}));

const StateFooter = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between"
}));

function Stats({
	label = "New Label",
	text,
	color, // danger, info, neutral, primary, success, warning
	number,
	footer,
	action,
	isLoading
}) {
	return (
		<Card
			sx={{
				boxShadow: ` 0px 11px 22px rgb(0 0 0 / 5%)`
			}}
		>
			<StateHeader>
				<Typography level="Body1">{label}</Typography>
				{!action ? null : (
					<IconButton
						variant="plain"
						color="neutral"
						p={0}
						sx={{
							"--IconButton-size": "30px"
						}}
						disabled={action.disabled ? false : true}
						onClick={action.onClick}
					>
						{action.icon}
					</IconButton>
				)}
			</StateHeader>
			<StateBody>
				<Typography level="h3" component="span" color={color}>
					{isLoading ? (
						<Box>
							<Skeleton
								height={40}
								width={100}
								animation="wave"
							/>
						</Box>
					) : !text ? (
						number
					) : (
						text
					)}
				</Typography>
			</StateBody>
			<StateFooter>
				<Typography level="body4" component="span">
					{footer}
				</Typography>
			</StateFooter>
		</Card>
	);
}

Stats.defaultProps = {
	label: "Label",
	body: "",
	footer: "",
	action: {}
};

Stats.propTypes = {
	label: PropTypes.string,
	body: PropTypes.node,
	footer: PropTypes.node,
	action: PropTypes.object
};

export default Stats;
