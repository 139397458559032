import React, { useState } from "react";
import PropTypes from "prop-types";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({
	defaultValue,
	label,
	type = "text",
	placeholder,
	buttonText,
	onSubmit
}) => {
	const [inputValue, setInputValue] = useState(defaultValue);
	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};
	return (
		<FormControl>
			<FormLabel>{label}</FormLabel>
			<Input
				type={type}
				value={inputValue}
				onChange={handleInputChange}
				startDecorator={<SearchIcon />}
				placeholder={placeholder}
				onKeyPress={(ev) => {
					// Incase users hits enter to submit the form
					if (ev.key === "Enter") {
						ev.preventDefault();
						onSubmit(inputValue);
					}
				}}
				endDecorator={
					<Button
						variant="soft"
						onClick={onSubmit.bind(this, inputValue)}
					>
						{buttonText}
					</Button>
				}
			/>
		</FormControl>
	);
};

SearchBar.defaultProps = {
	label: "Label",
	placeholder: "placeholder",
	buttonText: "Text"
};

SearchBar.propTypes = {
	label: PropTypes.string,
	buttonText: PropTypes.string,
	placeholder: PropTypes.string,
	onSubmit: PropTypes.func.isRequired
};

export default SearchBar;
