import { createSlice } from "@reduxjs/toolkit";
import { fetchDashboardStatus } from "./api";

const initialState = {
	dashboardStatus: null,
	status: "idle", //| "loading" | "succeeded" | "failed"
	error: null
};

export const iherbDashboardSlice = createSlice({
	name: "iherbDashboard",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchDashboardStatus.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchDashboardStatus.fulfilled, (state, action) => {
				state.status = "succeeded";

				state.dashboardStatus = action.payload;
			})
			.addCase(fetchDashboardStatus.rejected, (state, action) => {
				state.status = "failed";

				if (action.payload && action.payload.status === 404) {
					// handle 404 error
					state.error = "User not found";
				} else if (action.error) {
					// handle network error
					state.error = action.error.message;
				}

				if (action.payload) {
					let error = action.payload.detail;
					if (
						error === "expired" ||
						error === "invalid token: Invalid header padding"
					) {
						state.error = "User not found";
						alert(
							"Token expired you will be redirected back to the loging page"
						);
						localStorage.removeItem("userToken");
						window.location.href = "/login";
					} else {
						state.error = error;
					}
				} else if (action.error) {
					state.error = action.error.message;
				}
			});
	}
});

export const {} = iherbDashboardSlice.actions;

export const totalIherbPendingProducts = (state) =>
	state.iherbDashboard.dashboardStatus.pending_products;

export default iherbDashboardSlice.reducer;
