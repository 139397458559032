import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/joy/Box";

import { styled } from "@mui/system";

import Sidebar from "./Sidebar";
import Header from "./Topbar";
import Main from "./Main";
import Navigation from "../UI/Navigation";
import Logo from "../UI/Logo";
import UserSetting from "../UI/UserSetting";

const Root = styled(Box)((props) => ({
	display: "grid",
	gridTemplateRows: "64px 0.5fr 1fr",
	gridTemplateColumns: "1fr",
	gridTemplateAreas: `
		'topbar'
		'main'
	`,
	[props.theme.breakpoints.up("sm")]: {
		gridTemplateColumns: "minmax(64px, 200px) minmax(450px, 1fr)",
		gridTemplateRows: "64px 1fr",
		gridTemplateAreas: `
		'topbar topbar'
		'sidebar main'
		`,
		minHeight: "100vh"
	},
	[props.theme.breakpoints.up("md")]: {
		gridTemplateColumns: "322px 1fr"
	}
}));

function Layout() {
	return (
		<Root>
			<Header>
				<Box>
					<Logo />
				</Box>
				<Box>
					<UserSetting />
				</Box>
			</Header>
			<Sidebar>
				<Navigation />
			</Sidebar>
			<Main>
				<Outlet />
			</Main>
		</Root>
	);
}

export default Layout;
