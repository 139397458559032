import React from "react";
import Box from "@mui/joy/Box";

function Main(props) {
	return (
		<Box
			component="main"
			gridArea="main"
			{...props}
			sx={[
				{
					p: 3,
					bgcolor: "background.surface",
					borderRight: "1px solid",
					borderColor: "divider",
					height: "calc(100vh - 115px)",
					overflowY: "scroll"
				},
				...(Array.isArray(props.sx) ? props.sx : [props.sx])
			]}
		/>
	);
}

export default Main;
