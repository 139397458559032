import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_BASE_URL = `${process.env.REACT_APP_VNSUITE_API}/iherb`;

// FETCH PRODUCTS
export const fetchProducts = createAsyncThunk(
	"iherb/fetchProducts",
	async (_, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(
				`${API_BASE_URL}/products?limit=8`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchProductByUPC = createAsyncThunk(
	"iherb/fetchProductByUPC",
	async (upc, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(
				`${API_BASE_URL}/products/upc/${upc}`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchNextSetOfProducts = createAsyncThunk(
	"iherb/fetchNextSetOfProducts",
	async (params, thunkAPI) => {
		const { next_cursor } = params;
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(
				`${API_BASE_URL}/products?limit=8&next_cursor=${next_cursor}`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchProductById = createAsyncThunk(
	"iherb/fetchProductById",
	async (id, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(`${API_BASE_URL}/product/${id}`, {
				headers: {
					Accept: "application/json",
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				}
			});
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchProductKeywords = createAsyncThunk(
	"iherb/fetchProductKeywords",
	async (id, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await await axios.get(
				`${API_BASE_URL}/product/${id}/keywords`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchProductCSV = createAsyncThunk(
	"iherb/fetchProductCSV",
	async (id, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await await axios.get(
				`${API_BASE_URL}/product/${id}/keywords/csv`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchRelatedProductsCSV = createAsyncThunk(
	"iherb/fetchRelatedProductsCSV",
	async (id, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await await axios.get(
				`${API_BASE_URL}/product/${id}/related_products/csv`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

// FETCH KEYWORDS
export const fetchKeywords = createAsyncThunk(
	"iherb/fetchKeywords",
	async (_, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_VNSUITE_API}/iherb/keywords?limit=8`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchNextSetOfKeywords = createAsyncThunk(
	"iherb/fetchNextSetOfKeywords",
	async (params, thunkAPI) => {
		const { next_cursor } = params;
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_VNSUITE_API}/iherb/keywords?limit=8&next_cursor=${next_cursor}`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const fetchKeywordsByKeyword = createAsyncThunk(
	"iherb/fetchKeywordsByKeyword",
	async (keyword, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_VNSUITE_API}/iherb/keyword/${keyword}`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);

			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

// FETCH KEYWORDS
export const fetchDashboardStatus = createAsyncThunk(
	"iherb/fetchDashboardStatus",
	async (_, thunkAPI) => {
		const token = thunkAPI.getState().auth.accessToken;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_VNSUITE_API}/iherb/dashboard/stats`,
				{
					headers: {
						Accept: "application/json",
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);
