import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	isAuthenticated: false,
	user: null,
	isLoading: false,
	error: null,
	accessToken: null
};

export const getAccessToken = createAsyncThunk(
	"auth/getAccessToken",
	async (token) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_VNSUITE_API}/authorize?token=${token}`
			);
			return { ...response.data };
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const checkAuth = () => async (dispatch) => {
	let isAuthenticated = false;
	const userToken = localStorage.getItem("userToken")
		? localStorage.getItem("userToken")
		: null;

	if (userToken) {
		dispatch(setIsAuthenticated(true));
		dispatch(setAccessToken(userToken));
		isAuthenticated = true;
	}

	return isAuthenticated;
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setIsAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		setAccessToken: (state, action) => {
			state.accessToken = action.payload;
		},
		logoutSuccess: (state) => {
			state = { ...initialState };
			localStorage.removeItem("userToken");
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getAccessToken.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(getAccessToken.fulfilled, (state, action) => {
			state.accessToken = action.payload.access_token;
			state.isAuthenticated = true;
			state.isLoading = false;
			state.error = null;
			localStorage.setItem("userToken", action.payload.access_token);
		});
		builder.addCase(getAccessToken.rejected, (state, action) => {
			state.accessToken = null;
			state.accessKey = null;
			state.isLoading = false;
			state.error = action.payload;
		});
	}
});

export const { logoutSuccess, setUser, setIsAuthenticated, setAccessToken } =
	authSlice.actions;

export const userIsAuthenticated = (state) => state.auth.isAuthenticated;
export const userAccessToken = (state) => state.auth.accessToken;

export default authSlice.reducer;
