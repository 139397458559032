import React, { useState } from "react";

import { useSelector } from "react-redux";
import {
	selectProductById,
	selectProductsByKeyword
} from "./iherbProductsSlice";

import { CustomTableDefault } from "../../components/UI/Tables";
import ProductCSVButton from "./IherbProductCSVButton";

const IherbProductKeywordsTable = () => {
	const keywords = useSelector(selectProductsByKeyword);
	const product = useSelector(selectProductById);

	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize] = useState(8);

	const handleNextPage = () => {
		setCurrentPage(currentPage + pageSize);
	};

	const handlePreviousPage = () => {
		setCurrentPage(currentPage - pageSize);
	};
	return (
		<CustomTableDefault
			hoverRow={false}
			tableHead={[
				{
					label: "Keyword",
					key: "keyword"
				},
				{
					label: "Search Rank",
					key: "rank"
				},
				{
					label: "Page",
					key: "page"
				}
			]}
			tableHeadAction={
				<ProductCSVButton
					button={true}
					variant="soft"
					productId={product._id}
					fileName={`iherb_research_${product._id}_${product.brand}`}
				/>
			}
			tableRow={keywords}
			title={`Keywords (${keywords.length})`}
			pageSize={pageSize}
			tablePagination={{
				totalResults: keywords.length,
				startIndex: currentPage,
				nextPage: handleNextPage,
				previousPage: handlePreviousPage
			}}
		></CustomTableDefault>
	);
};

export default IherbProductKeywordsTable;
