import React, { useState } from "react";

import { useSelector } from "react-redux";
import {
	selectPurchasedTogetherProducts,
	selectProductById
} from "./iherbProductsSlice";

import { CustomTableDefault } from "../../components/UI/Tables";

const IherbFrequentlyPurchasedTogetherTable = () => {
	const product = useSelector(selectProductById);
	const purchasedTogetherProducts = useSelector(
		selectPurchasedTogetherProducts
	);

	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize] = useState(8);

	const handleNextPage = () => {
		setCurrentPage(currentPage + pageSize);
	};

	const handlePreviousPage = () => {
		setCurrentPage(currentPage - pageSize);
	};

	return (
		<CustomTableDefault
			title={`Frequently Purchased Together (${purchasedTogetherProducts.length})`}
			description={`Competitor product page that ${product.name} shows up in.`}
			hoverRow={false}
			tableHead={[
				{
					label: "Name",
					key: "name",
					width: "60%"
				},
				{
					label: "UPC",
					key: "upc",
					width: "30%"
				},
				{
					label: "",
					key: "url",
					width: "10%"
				}
			]}
			tableRow={purchasedTogetherProducts}
			pageSize={pageSize}
			tablePagination={{
				totalResults: purchasedTogetherProducts.length,
				startIndex: currentPage,
				nextPage: handleNextPage,
				previousPage: handlePreviousPage
			}}
		/>
	);
};

export default IherbFrequentlyPurchasedTogetherTable;
