import { useSelector, useDispatch } from "react-redux";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import { closeDialog } from "./dialogSlice";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const GlobalDialog = () => {
	const dispatch = useDispatch();
	const { isOpen, message, title, error, primaryButton, secondaryButton } =
		useSelector((state) => state.dialog);

	const handleClose = () => {
		dispatch(closeDialog());
	};

	return (
		<Modal
			aria-labelledby="Global Dialog"
			onClose={handleClose}
			open={isOpen}
		>
			<ModalDialog
				size="md"
				variant="outlined"
				role="alertdialog"
				aria-labelledby="alert-dialog-modal-title"
				aria-describedby="alert-dialog-modal-description"
			>
				{/* <ModalClose /> */}
				<Typography
					level="h4"
					component="h2"
					startDecorator={error ? <WarningRoundedIcon /> : null}
					color={error ? "danger" : ""}
				>
					{title}
				</Typography>
				<Typography
					level="inherit"
					sx={{ textTransform: "captalize", paddingBlock: 2 }}
				>
					{message}
				</Typography>
				<Box
					sx={{
						display: "flex",
						gap: 1,
						pt: 1,
						justifyContent: "flex-end"
					}}
				>
					{/* {secondaryButton ? (
						primaryButton
					) : (
						<Button
							variant="plain"
							color="neutral"
							onClick={handleClose}
						>
							Cancel
						</Button>
					)} */}
					<Button
						variant="solid"
						onClick={handleClose}
						color={error ? "danger" : ""}
						fullWidth
					>
						{primaryButton ? primaryButton : "Close"}
					</Button>
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default GlobalDialog;
