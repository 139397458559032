import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Redux Api
import {
	fetchNextSetOfProducts,
	fetchKeywordsByKeyword,
	fetchProducts,
	fetchProductById,
	fetchProductByUPC
} from "./api";

// Redux Slices
import { totalProduct, setProducts } from "./iherbProductsSlice";
import { setkeywordsByKeyword } from "./iherbKeywordsSlice";

import { openDialog } from "../dialog/dialogSlice";

// Matieral UI
import Box from "@mui/joy/Box";

// Custom Components
import { SearchBar, SelectField } from "../../components/UI/Forms";
import { CustomTableDefault } from "../../components/UI/Tables";

const IherbProductsTable = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const productsByKeyword = useSelector(
		(state) => state.iherbKeywords.keywordsByKeyword
	);
	const products = useSelector((state) => state.iherbProducts.products);
	const productStatus = useSelector((state) => state.iherbProducts.status);

	const totalProducts = useSelector(totalProduct);

	const [currentPage, setCurrentPage] = useState(0);

	const [resetTable, setResetTable] = useState(false);

	const [pageSize] = useState(8);

	const [searchType, setSearchType] = useState("upc");

	const handleNextPage = () => {
		const next_cursor = products[products.length - 1];

		if (currentPage + pageSize >= products.length) {
			// Fetch new data if the the list does not hold index
			dispatch(
				fetchNextSetOfProducts({
					next_cursor: next_cursor._id
				})
			);
		}

		setResetTable(false);
		setCurrentPage(currentPage + pageSize);
	};

	const handlePreviousPage = () => {
		setCurrentPage(currentPage - pageSize);
	};

	const handleSearchSubmit = (searchValue) => {
		if (searchValue === "") {
			dispatch(fetchProducts()).then((response) => {
				const payload = response.payload.results;
				dispatch(setProducts(payload));
				dispatch(setkeywordsByKeyword([]));
				setSearchType("upc");
			});
		} else {
			if (searchType === "upc") {
				dispatch(fetchProductByUPC(searchValue)).then((response) => {
					const payload = response.payload;
					if (payload._id) {
						navigate(`${payload._id}`);
					} else {
						dispatch(
							openDialog({
								title: "Oops",
								message: (
									<>
										Seems like
										<strong> {searchValue}</strong> does not
										exist
									</>
								),
								error: true
							})
						);
					}
				});
			} else {
				dispatch(fetchKeywordsByKeyword(searchValue)).then(
					(response) => {
						let payload = response.payload;
						if (payload.hasOwnProperty("detail")) {
							dispatch(
								openDialog({
									title: "Oops",
									message: (
										<div>
											Seems like{" "}
											<strong> {searchValue}</strong> does
											not exist we may not have it yet on
											the database yet, please try another
											keyword.
										</div>
									),
									error: true
								})
							);
						}
					}
				);
			}
		}

		setResetTable(true);
		setCurrentPage(0);
	};
	const handleSelectChange = (event) => {
		setSearchType(event.target.getAttribute("name"));
	};

	const handleNavigateTo = (data) => {
		dispatch(fetchProductById(data._id)).then(() => {
			navigate(`${data._id}`);
		});
	};
	return (
		<CustomTableDefault
			sx={{ mb: 8 }}
			isLoading={productStatus === "loading" ? true : false}
			title="Products"
			hoverRow={true}
			tableHead={[
				{
					label: "Product",
					key: { name: "name", image: "image" },
					width: "60%"
				},

				{
					label: "UPC",
					key: "upc",
					width: "30%"
				},
				{
					label: searchType === "upc" ? "Total Keywords" : "Keyword",
					key: searchType === "upc" ? "num_keywords" : "keyword",
					width: "30%"
				},
				{
					key: "csv",
					width: "30%",
					fileName: "iherb_research_"
				}
			]}
			tableRow={searchType === "upc" ? products : productsByKeyword}
			navigateTo={handleNavigateTo}
			pageSize={pageSize}
			reset={resetTable}
			tablePagination={{
				totalResults:
					searchType === "upc"
						? totalProducts
						: productsByKeyword?.length,
				startIndex: currentPage,
				nextPage: handleNextPage,
				previousPage: handlePreviousPage
			}}
		>
			<Box
				sx={{
					p: 2,
					display: "grid",
					gridTemplateColumns: "0.3fr 1fr",
					columnGap: "10px"
				}}
			>
				<SelectField
					label="Search Type"
					options={[
						{ label: "keyword", key: "keyword" },
						{ label: "upc", key: "upc" }
					]}
					selectedOption={searchType}
					onChange={handleSelectChange}
				/>
				<SearchBar
					defaultValue={
						productsByKeyword.length > 0
							? productsByKeyword[0]?.keyword
							: ""
					}
					label={"Search Value"}
					type={searchType === "upc" ? "number" : "string"}
					placeholder={
						searchType === "upc" ? "850026121674" : "coconut"
					}
					buttonText="Search"
					onSubmit={handleSearchSubmit}
				/>
			</Box>
		</CustomTableDefault>
	);
};

export default IherbProductsTable;
