import React, { useState, useEffect } from "react";
import Avatar from "@mui/joy/Avatar";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useAuth0 } from "@auth0/auth0-react";

import { useDispatch } from "react-redux";
import { logoutSuccess } from "../../features/auth/authSlice";

const UserSetting = () => {
	const dispatch = useDispatch();
	const { logout, user } = useAuth0();
	const [userMenuAnchor, setUserMenuAnchor] = useState(null);
	const openUserMenu = Boolean(userMenuAnchor);

	const handleLogout = () => {
		logout({ returnTo: window.location.origin }).then(() =>
			dispatch(logoutSuccess())
		);
	};

	return (
		<>
			<IconButton
				color="primary"
				id="user-menu-button"
				aria-controls={openUserMenu ? "user-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={openUserMenu ? "true" : undefined}
				onClick={(e) => setUserMenuAnchor(e.currentTarget)}
				variant="plain"
				sx={{
					"&:hover": {
						backgroundColor: "transparent"
					}
				}}
			>
				<Avatar
					src={user?.picture}
					alt={`${user?.given_name} ${user?.family_name}`}
					size="md"
				/>
			</IconButton>

			<Menu
				id="user-menu"
				anchorEl={userMenuAnchor}
				open={openUserMenu}
				onClose={() => setUserMenuAnchor(null)}
				MenuListProps={{
					"aria-labelledby": "user-menu"
				}}
			>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</>
	);
};

export default UserSetting;
