import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import dialogReducer from "../features/dialog/dialogSlice";
import iherbKeywordsReducer from "../features/iherb/iherbKeywordsSlice";
import iherbProductsReducer from "../features/iherb/iherbProductsSlice";
import iherbDashboardReducer from "../features/iherb/iherbDashboardSlice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		dialog: dialogReducer,
		iherbKeywords: iherbKeywordsReducer,
		iherbProducts: iherbProductsReducer,
		iherbDashboard: iherbDashboardReducer
	}
});
