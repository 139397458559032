import React, { useEffect } from "react";
import "./App.css";

import { Routes, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import { KeywordList, Keyword } from "./pages/iherb";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import { checkAuth } from "./features/auth/authSlice";
import { useDispatch } from "react-redux";
import GlobalDialog from "./features/dialog/GlobalDialog";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkAuth());
	}, []);

	return (
		<>
			<GlobalDialog />
			<ScrollToTop />
			<Routes>
				<Route element={<ProtectedRoutes />}>
					<Route path="/iherb/keyword-research" element={<Layout />}>
						<Route element={<KeywordList />} index />
						<Route element={<Keyword />} path=":productId" />
					</Route>
				</Route>
				<Route path="/login" element={<Login />} />
				<Route path="*" element={<Login />} />
			</Routes>
		</>
	);
}

export default App;
