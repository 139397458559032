import React from 'react';
import Box from '@mui/joy/Box';

const Topbar = (props) => {
  return (
    <Box
      component="nav"
      className="topbar"
      {...props}
      sx={[
        {
          p: 3,
          gap: 2,
          bgcolor: 'background.surface',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gridColumn: '1 / -1',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 1100,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};

export default Topbar;
