import { React, useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";

import Box from "@mui/joy/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { checkAuth } from "./../features/auth/authSlice";
import { useDispatch } from "react-redux";

const ProtectedRoutes = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkAuth()).then((authenticated) => {
			if (!authenticated) {
				setIsAuthenticated(false);
			} else {
				setIsAuthenticated(true);
			}
			setIsLoading(false);
		});
	}, []);

	if (isLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					height: "100vh",
					width: "100wv",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<CircularProgress size={65} />
			</Box>
		);
	}

	if (!isAuthenticated) {
		return <Navigate to="/login" />;
	}

	return <Outlet />;
};

export default ProtectedRoutes;
