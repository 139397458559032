import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
	fetchProductById,
	fetchProductKeywords
} from "../../features/iherb/api";
import {
	selectProductById,
	selectProductsByKeyword
} from "../../features/iherb/iherbProductsSlice";

import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Link from "@mui/joy/Link";
import Rating from "@mui/material/Rating";
import OpenInNew from "@mui/icons-material/OpenInNew";

import Header from "../../components/UI/Header";
import { CustomTableDefault } from "../../components/UI/Tables";
import IherbFrequentlyPurchasedTogetherTable from "../../features/iherb/IherbFrequentlyPurchasedTogetherTable";
import IherbRelatedProducts from "../../features/iherb/IherbRelatedProducts";
import IherbProductKeywordsTable from "../../features/iherb/IherbProductKeywordsTable";

function Keyword() {
	const prevProductIdRef = useRef();
	let { productId } = useParams();
	const dispatch = useDispatch();
	const product = useSelector(selectProductById);
	const keywords = useSelector(selectProductsByKeyword);

	const sectionRefs = {
		keywords: useRef(null),
		categories: useRef(null),
		purchased_together: useRef(null),
		related_products: useRef(null)
	};

	const handleScrollTo = (section) => {
		sectionRefs[section].current.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		prevProductIdRef.current = product?._id;
		if (!product) {
			dispatch(fetchProductById(productId));
		}

		if (prevProductIdRef.current === product?._id) {
			dispatch(fetchProductKeywords(productId));
		}
	}, [dispatch]);

	if (!product) {
		return <div>Loading</div>;
	} else {
		return (
			<Box>
				<Header
					title="Product"
					breadcrumbs={[
						{
							label: "IHerb Keyword Research",
							link: "/iherb/keyword-research"
						},
						{
							label: product?.upc,
							link: "/"
						}
					]}
				/>
				<Grid container spacing={4} mb={3}>
					<Grid md={5}>
						<Card
							sx={{ flexGrow: 1, img: { width: "100%" } }}
							variant="plain"
						>
							<img
								src={product?.image}
								srcSet={product?.image}
								loading="lazy"
								alt={product?.name}
							/>
						</Card>
					</Grid>
					<Grid md={7}>
						<Box>
							<Typography level="body3">
								UPC: {product?.upc}
							</Typography>
						</Box>
						<Typography level="h3" component="h2" mb={2}>
							<Link
								color="neutral"
								variant="null"
								sx={{
									padding: 0,
									display: "inline",
									color: "#25252C"
								}}
								target="_blank"
								href={product?.url}
							>
								{product?.name}
								<OpenInNew sx={{ ml: 1 }} />
							</Link>
						</Typography>
						<Stack direction="row" spacing={3} mb={4}>
							<Typography level="h6" component="p">
								${product?.price}
							</Typography>
							<Rating
								name="read-only"
								value={product?.rating}
								readOnly
							/>
						</Stack>
						<Sheet
							color="primary"
							variant="soft"
							sx={{ padding: 2, borderRadius: 5 }}
						>
							<Stack spacing={2} direction="row" flexWrap="wrap">
								<Typography level="body2">
									Keywords:{" "}
									<Link
										component="button"
										onClick={() =>
											handleScrollTo("keywords")
										}
									>
										{keywords?.length}
									</Link>
								</Typography>
								<Typography level="body2">
									Categories:{" "}
									<Link
										component="button"
										onClick={() =>
											handleScrollTo("categories")
										}
									>
										{product?.categories?.length > 0
											? product?.categories?.length
											: 0}
									</Link>
								</Typography>
								<Typography level="body2">
									Purchase Together:{" "}
									<Link
										component="button"
										onClick={() =>
											handleScrollTo("purchased_together")
										}
									>
										{product?.purchased_together.length}
									</Link>
								</Typography>
								<Typography level="body2">
									Related Products:{" "}
									<Link
										component="button"
										onClick={() =>
											handleScrollTo("related_products")
										}
									>
										{product?.related_products.length}
									</Link>
								</Typography>
							</Stack>
						</Sheet>
					</Grid>
				</Grid>
				<Box ref={sectionRefs.keywords} component="section" mb={7}>
					<IherbProductKeywordsTable />
				</Box>
				<Box ref={sectionRefs.categories} component="section" mb={7}>
					<CustomTableDefault
						hoverRow={false}
						tableHead={[
							{
								label: "Name",
								key: "name"
							},
							{
								label: "Search Rank",
								key: "rank"
							}
						]}
						tableRow={product?.categories}
						title={`Categories (${
							product?.categories?.length > 0
								? product?.categories?.length
								: 0
						})`}
						pageSize={product?.categories?.length}
					></CustomTableDefault>
				</Box>

				<Box
					ref={sectionRefs.purchased_together}
					component="section"
					mb={7}
				>
					<IherbFrequentlyPurchasedTogetherTable />
				</Box>
				<Box
					ref={sectionRefs.related_products}
					component="section"
					mb={7}
				>
					<IherbRelatedProducts />
				</Box>
			</Box>
		);
	}
}

export default Keyword;
