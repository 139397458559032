import React from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";

// Icons import
import InboxRoundedIcon from "@mui/icons-material/InboxRounded";

function Navigation() {
	const navigate = useNavigate();
	return (
		<List size="sm" sx={{ "--ListItem-radius": "8px" }}>
			<ListItem nested>
				<ListSubheader>
					Tools
					{/* <IconButton
                        size="sm"
                        variant="plain"
                        color="primary"
                        sx={{ '--IconButton-size': '24px', ml: 'auto' }}
                    >
                        <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" />
                    </IconButton> */}
				</ListSubheader>
				<List
					aria-labelledby="nav-list-browse"
					sx={{
						"& .JoyListItemButton-root": { p: "8px" }
					}}
				>
					<ListItem>
						<ListItemButton variant="soft" color="primary">
							<ListItemDecorator sx={{ color: "inherit" }}>
								<InboxRoundedIcon fontSize="small" />
							</ListItemDecorator>
							<ListItemContent
								onClick={() =>
									navigate("/iherb/keyword-research")
								}
							>
								IHerb Keyword Research
							</ListItemContent>
						</ListItemButton>
					</ListItem>
				</List>
			</ListItem>
		</List>
	);
}

export default Navigation;
