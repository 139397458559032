import React from "react";
import PropTypes from "prop-types";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

const SelectField = ({ label, options, selectedOption, onChange }) => {
	return (
		<FormControl>
			<FormLabel
				id="select-field-demo-label"
				htmlFor="select-field-demo-button"
			>
				{label}
			</FormLabel>
			<Select
				value={selectedOption.toLowerCase()}
				slotProps={{
					button: {
						id: "select-field-demo-button",
						"aria-labelledby":
							"select-field-demo-label select-field-demo-button"
					}
				}}
				onChange={onChange}
			>
				{options.map((option) => (
					<Option
						key={option.key}
						name={option.key}
						value={option.key}
					>
						{option.label}
					</Option>
				))}
			</Select>
		</FormControl>
	);
};

SelectField.defaultProps = {
	label: "Label",
	options: [],
	selectedOption: ""
};

SelectField.propTypes = {
	label: PropTypes.string,
	options: PropTypes.array,
	selectedOption: PropTypes.string,
	handleOnChange: PropTypes.func
};

export default SelectField;
