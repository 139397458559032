import React, { useEffect, useState, useMemo } from "react";

import Table from "@mui/joy/Table";
import Stack from "@mui/joy/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/joy/Typography";

import TablePagination from "@mui/material/TablePagination";

import buildTableRow from "../../../utils/buildTableRow";
import PreloadSkelton from "../PreloadSkelton";

const CustomTableDefault = ({
	sx,
	size = "md",
	isLoading,
	hoverRow,
	tableHead,
	tableHeadAction,
	tableRow,
	navigateTo,
	pageSize = 5,
	tablePagination = {
		nextPage: null,
		previousPage: null,
		startIndex: 0,
		totalResults: 0
	},
	title,
	description,
	reset = false,
	children
}) => {
	const [page, setPage] = useState(0);
	const startIndex = !tablePagination ? 0 : tablePagination?.startIndex;
	const endIndex = startIndex + pageSize;

	const handleChangePage = (event, newPage) => {
		event.preventDefault();
		if (newPage > page) {
			tablePagination.nextPage();
		} else {
			tablePagination.previousPage();
		}
		setPage(newPage);
	};

	const renderTableRow = useMemo(() => {
		if (isLoading) {
			return (
				<Box component="tr">
					<td colSpan={tableHead.length}>
						<Box>
							<PreloadSkelton totalItems={pageSize} />
						</Box>
					</td>
				</Box>
			);
		} else if (tableRow.length > 0) {
			return tableRow.slice(startIndex, endIndex).map((row, idx) => (
				<Box
					component="tr"
					sx={{
						cursor: hoverRow ? "pointer" : "inherit"
					}}
					key={idx}
					onClick={navigateTo ? navigateTo.bind(this, row) : null}
				>
					{buildTableRow(tableHead, row, idx)}
				</Box>
			));
		} else {
			return (
				<Box component="tr">
					<td colSpan={tableHead.length}>No Results</td>
				</Box>
			);
		}
	}, [
		isLoading,
		hoverRow,
		tableHead,
		tableRow,
		navigateTo,
		pageSize,
		tablePagination
	]);

	useEffect(() => {
		if (reset) {
			setPage(0);
		}
	}, [reset]);

	return (
		<Paper
			elevation={3}
			sx={{
				...sx,
				pt: 1,
				borderRadius: "lg",
				boxShadow: ` 0px 11px 22px rgb(0 0 0 / 5%)`
			}}
		>
			<Box sx={{ paddingInline: 2, paddingBlock: 1 }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{title !== "" ? (
						<Typography level="h5">{title}</Typography>
					) : null}
					{tableHeadAction}
				</Stack>
				{description !== "" ? (
					<Typography level="body2" component={"p"} mt={1}>
						{description}
					</Typography>
				) : null}
			</Box>
			{children}
			<Table
				hoverRow={hoverRow}
				variant="soft"
				color="neutral"
				size={size}
				sx={{
					// minHeight: tableRow.length > 0 ? tableRow.length : "auto",
					captionSide: "top",
					"th, td": {
						paddingInline: 2
					},
					"& tbody": { bgcolor: "background.surface" },
					"thead th": {
						bgcolor: "neutral.50",
						verticalAlign: "middle",
						borderBottom: "transparent"
					}
				}}
			>
				<thead>
					<tr>
						{tableHead?.map((th, idx) => {
							return (
								<th
									key={idx}
									style={{
										width: !th.width
											? `${100 / tableHead.length + 1}%`
											: th.width
									}}
								>
									{th.label}
								</th>
							);
						})}
					</tr>
				</thead>

				<tbody>{renderTableRow}</tbody>
				{tablePagination.totalResults > 0 ? (
					<tfoot>
						<tr>
							<td
								colSpan={6}
								style={{ backgroundColor: "white" }}
							>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 2,
										justifyContent: "flex-end"
									}}
								>
									<TablePagination
										component="div"
										count={tablePagination?.totalResults}
										page={page}
										onPageChange={handleChangePage}
										rowsPerPage={pageSize}
										rowsPerPageOptions={[]}
									/>
								</Box>
							</td>
						</tr>
					</tfoot>
				) : null}
			</Table>
		</Paper>
	);
};

CustomTableDefault.defaultProps = {
	tableHead: [],
	tableRow: [],
	title: "Table Title",
	description: ""
};

CustomTableDefault.propTypes = {
	tableHead: PropTypes.array,
	tableRow: PropTypes.array,
	title: PropTypes.string,
	description: PropTypes.string
};

export default CustomTableDefault;
